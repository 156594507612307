import React from "react";
import Navbar from "./components/Navbar/Navbar";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="content">
        <div className="scroll-slider"></div>
      </div>
      <div className="social-icons">
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="facebook.png" alt="Facebook" />
        </a>
        <a
          href="https://twitter.com/GANTokenGameFi"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="twitter.png" alt="Twitter" />
        </a>
        <a
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="instagram.png" alt="Instagram" />
        </a>
        <a
          href="https://t.me/GAN_TOKEN"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="telegram.png" alt="Telegram" />
        </a>
        <p className="copyright">
          Copyright © 2024 GAN Token. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="https://gantoken.network/">
          <img src="logo.png" alt="Logo" />
        </a>
      </div>
      <div className={`navbar-links ${isOpen ? "open" : ""}`}>
        <li>
          <a href="https://gantoken.network/">Home</a>
        </li>
        <li>
          <a href="#tokenomics">Tokenomics</a>
        </li>
        <li>
          <a href="#whitepaper">Whitepaper</a>
        </li>
      </div>
      <button className="navbar-button">Connect Wallet</button>
      <div className="navbar-toggle" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
